import { createTheme } from "@mui/material";
import AlpinaTypewriterLightWoff2 from "./fonts/GT-Alpina-Typewriter-Light-Trial.woff2";
import AlpinaTypewriterLightItalicWoff2 from "./fonts/GT-Alpina-Typewriter-Light-Italic-Trial.woff2";
import AlpinaStandardLightWoff2 from "./fonts/GT-Alpina-Standard-Light-Trial.woff2";
import AlpinaStandardLightItalicWoff2 from "./fonts/GT-Alpina-Standard-Light-Italic-Trial.woff2";

const colors = {
  grey1: "#ffffff",
  grey2: "#fafafa",
  grey3: "#f5f5f5", // off white
  grey4: "#f0f0f0",
  grey5: "#dedede",
  grey6: "#c2c2c2",
  grey7: "#979797",
  grey8: "#818181",
  grey9: "#606060",
  grey10: "#3c3c3c",
  red: "#f10000",
  background: "", // change the name to color
};
const palette = {
  primary: {
    // main: "#818181",
    main: colors.grey9, //"#333333",
    light: colors.grey3, //"#333333", //"#c2c2c2",
    dark: colors.grey9, //"#3c3c3c",
  },
  secondary: {
    main: colors.grey5, //"#333333",
    light: colors.grey2, //"#333333", //"#c2c2c2",
    dark: colors.grey7, //"#3c3c3c",
  },
  error: {
    main: colors.red,
  },
  background: {
    // default: "#F9F6F2", // warm color
    default: colors.grey4,
    // default: colors.grey3, // off white
  },
  //? t is for text
  t: {
    dark: colors.grey10,
    light: colors.grey2,
    lightDark: colors.grey8,
    darkLight: colors.grey4,
  },
};

const typography = {
  fontFamily: ["AlpinaStandard", "AlpinaTypewriter"].join(","),
  h1: {
    fontFamily: "AlpinaTypewriter",
    fontWeight: 400,
    fontSize: 24,
  },
  author: {
    fontFamily: "AlpinaStandard",
    fontWeight: 400,
    fontSize: 14,
    fontStyle: "Italic",
  },
  title: {
    fontFamily: "AlpinaStandard",
    fontWeight: 400,
    fontSize: 14,
    textTransform: "uppercase",
  },

  critique: {
    fontFamily: "AlpinaStandard",
    fontWeight: 400,
    fontSize: 14,
  },

  body1: {
    fontFamily: "AlpinaStandard",
    fontWeight: 500,
  },
  body2: {
    fontFamily: "AlpinaTypewriter",
    fontWeight: 400,
    fontSize: "0.9375rem",
  },
  t1: {
    fontFamily: "AlpinaTypewriter",
    fontWeight: 400,
    fontSize: 12,
  },
  t2: {
    fontFamily: "AlpinaTypewriter",
    fontWeight: 400,
    fontSize: 13,
  },
  t3: {
    fontFamily: "AlpinaTypewriter",
    fontWeight: 400,
    fontSize: 14,
  },
  t4: {
    fontFamily: "AlpinaTypewriter",
    fontWeight: 400,
    fontSize: 15,
  },
  t5: {
    fontFamily: "AlpinaTypewriter",
    fontWeight: 400,
    fontSize: 16,
  },
  s1: {
    fontFamily: "AlpinaStandard",
    fontWeight: 400,
    fontSize: 12,
  },
  s2: {
    fontFamily: "AlpinaStandard",
    fontWeight: 400,
    fontSize: 13,
  },
  s3: {
    fontFamily: "AlpinaStandard",
    fontWeight: 400,
    fontSize: 14,
  },
  s4: {
    fontFamily: "AlpinaStandard",
    fontWeight: 400,
    fontSize: 15,
  },
  s5: {
    fontFamily: "AlpinaStandard",
    fontWeight: 400,
    fontSize: 16,
  },
};

export const theme = createTheme({
  //TODO Background int textfield element is light blue when it's focused. I should change it
  palette,
  typography,
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
          @font-face {
            font-family: 'AlpinaStandard';
            font-style: normal;
            src: url(${AlpinaStandardLightWoff2}) format('woff2');
          }
  
          @font-face {
            font-family: 'AlpinaStandard';
            font-style: italic;
            src: url(${AlpinaStandardLightItalicWoff2}) format('woff2');
          }
  
          @font-face {
            font-family: 'AlpinaTypewriter';
            font-style: normal;
            src: url(${AlpinaTypewriterLightWoff2}) format('woff2');
          }
  
          @font-face {
            font-family: 'AlpinaTypewriter';
            font-style: italic;
            src: url(${AlpinaTypewriterLightItalicWoff2}) format('woff2');
          }
        `,
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          backgroundColor: palette.error.main, // Adjust the background color
          borderRadius: "4px", // Adjust the border radius
          // color: "darkblue", // Adjust the text color
          color: palette.background.default,
          // fontSize: "12px",
          // padding: 0,
          // margin: 0,
          width: "100%",
          textAlign: "center", // Center-align the text
          ...typography.s2,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          // color: palette.error.main, // Adjust the color value as needed
        },
        // checked: {
        //   // Modify the color of the checkbox icon (checked state)
        //   color: "blue", // Adjust the color value as needed
        // },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#f0f0f0", // Adjust the background color value as needed
          border: "0px", // Adjust the border value as needed
          borderRadius: "4px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: "AlpinaStandard",
          fontWeight: 400,
          fontSize: "15px", // Adjust the font size value as needed
        },
        MuiFocused: {
          "&.MuiInput-underline::before": {
            borderBottomWidth: "1px", // Thicker bottom border when focused
          },
        },
        underline: {
          borderBottomWidth: "1px",
          "&.Mui-focused": {
            borderBottomWidth: "1px", // Thicker bottom border when focused
          },
          "&:hover": {
            borderBottomWidth: "1px", // Thicker bottom border on hover
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          fontFamily: "AlpinaStandard",
          fontWeight: 400,
          fontSize: "15px", // Adjust the font size value as needed
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: "AlpinaStandard",

          fontSize: "15px",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "12px", // Adjust the font size value as needed
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInput-underline::before": {
            borderBottomWidth: "1px", // Adjust this value to make the border thinner
          },
          // "&.Mui-focused .MuiInput-underline::before": {
          //   borderBottomWidth: "1px", // Thicker bottom border when focused
          // },
          "&:hover .MuiInput-underline::before": {
            borderBottomWidth: "1px", // Thicker bottom border on hover
          },
        },
      },
    },
  },

  my: {
    width: "400px",
    formWidth: "280px",
  },
});
