import {
  Box,
  CssBaseline,
  Link,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { theme } from "./Theme";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mt: 10,
        }}
      >
        <Typography variant="h1">Les intellectuels</Typography>
        <Typography variant="t3" sx={{ mt: 5 }}>
          Hello
        </Typography>
        <Link href="https://app.lesintellectuels.com">
          <Typography variant="t2">Sign In</Typography>
        </Link>
      </Box>

      <Helmet>
        <meta charSet="utf-8" />
        <title style={{ fontFamily: "AlpinaStandard", fontSize: "16px" }}>
          Les intellectuels
        </title>
      </Helmet>
    </ThemeProvider>
  );
}
